import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaCode,
  FaMobileAlt,
  FaGamepad,
  FaPencilRuler,
  FaSearch,
  FaBullhorn,
  FaPalette,
  FaServer,
  FaArrowRight
} from 'react-icons/fa';

const servicesData = [
  {
    icon: <FaCode />,
    title: 'Web Development',
    description: 'Professional website development tailored to meet business needs and drive growth.',
    link: '/web-development'
  },
  {
    icon: <FaMobileAlt />,
    title: 'App Development',
    description: 'Custom mobile apps designed for iOS and Android, delivering seamless user experiences.',
    link: '/app-development'
  },
  {
    icon: <FaGamepad />,
    title: 'Game Development',
    description: 'Creating immersive and interactive game experiences for mobile and web platforms.',
    link: '/game-development'
  },
  {
    icon: <FaPencilRuler />,
    title: 'UI/UX Design',
    description: 'Designing intuitive and visually appealing interfaces to enhance user engagement.',
    link: '/ui-ux-design'
  },
  {
    icon: <FaSearch />,
    title: 'SEO',
    description: 'Boost your website’s visibility and rankings with effective search engine optimization.',
    link: '/seo'
  },
  {
    icon: <FaBullhorn />,
    title: 'Digital Marketing',
    description: 'Engage your audience and grow your brand through social media and digital campaigns.',
    link: '/digital-marketing'
  },
  {
    icon: <FaPalette />,
    title: 'Graphic Design',
    description: 'Create visually stunning and impactful designs for your brand and marketing materials.',
    link: '/graphic-design'
  },
  {
    icon: <FaServer />,
    title: 'Web Hosting',
    description: 'Reliable web hosting services with guaranteed uptime, fast speeds, and top security.',
    link: '/web-hosting'
  }
];

const Services = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [mousePositions, setMousePositions] = useState({});

  const handleMouseMove = (e, index) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePositions((prevPositions) => ({
      ...prevPositions,
      [index]: {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      }
    }));
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <section id="services" className="pt-10 px-6 sm:px-10 bg-gradient-to-b from-white to-blue-100">
      <div className="container mx-auto">
        <header className="text-center mb-12 sm:px-12 lg:px-28">
          <h3 className="text-2xl sm:text-3xl font-bold mb-4 text-gray-800">Services</h3>
          <p className="text-base sm:text-lg text-gray-700">
            We offer a range of professional services to meet the diverse needs of businesses. Explore our key services
            below.
          </p>
        </header>

        <div className="flex flex-wrap -mx-4">
          {servicesData.map((service, index) => (
            <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 mb-8">
              {/* Card Wrapper */}
              <div
                className="group relative bg-white rounded-lg shadow-lg p-6 flex flex-col h-full hover:scale-105 transition-transform duration-300 overflow-hidden"
                onMouseMove={(e) => handleMouseMove(e, index)}
                onMouseLeave={handleMouseLeave}
                style={
                  hoveredCard === index && mousePositions[index]
                    ? {
                        background: `radial-gradient(circle at ${mousePositions[index].x}px ${mousePositions[index].y}px, rgba(0, 123, 255, 0.2), transparent 40%)`
                      }
                    : {}
                }
              >
                {/* Icon and Title */}
                <div className="flex flex-col items-start mb-4">
                  <div className="text-3xl text-blue-500 mb-2">{service.icon}</div>
                  <h5 className="text-base sm:text-xl font-bold text-[#0f3c94]">
                   {service.title}
                  </h5>
                </div>

                {/* Description */}
                <p className="text-gray-700 text-sm sm:text-sm">
                  {service.description}
                </p>

                {/* Learn More Link */}
                <div className="mt-4">
                  <Link to={service.link} className="text-blue-500 hover:text-blue-700 flex items-center">
                    Learn More <FaArrowRight className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
