import React, { useState } from 'react';
import { FaEye, FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const filters = [
  { name: 'All', filter: '*' },
  { name: 'Web Design', filter: 'web-des' },
  { name: 'Web Development', filter: 'web-dev' },
  { name: 'App Design', filter: 'app-des' },
  { name: 'App Development', filter: 'app-dev' },
];

const portfolioItems = [
  {
    imgSrc: 'yml.jpg',
    title: 'E-Commerce Website',
    category: 'Web Development',
    filterClass: 'web-dev',
    website: 'https://ymlmart.com/',
    description: 'A robust e-commerce platform offering a wide range of products with seamless online shopping features, including cart management, secure payments, and order tracking.'
  },
  {
    imgSrc: 'yel (1).jpg',
    title: 'Business Website',
    category: 'Web Development',
    filterClass: 'web-dev',
    website: 'https://yelseva.com/login',
    description: 'A professional business website showcasing services, contact options, and client testimonials to establish online presence and enhance brand credibility.'
  },
  {
    imgSrc: 'yml app.jpg',
    title: 'Android APK',
    category: 'App Development',
    filterClass: 'app-dev',
    website: 'https://ymlmart.com/',
    description: 'An Android app providing mobile access to the YMLMart e-commerce platform, optimized for fast loading and smooth browsing, making shopping convenient on-the-go.'
  },
];

const Portfolio = () => {
  const [activeFilter, setActiveFilter] = useState('*');

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  const filteredItems = portfolioItems.filter(
    (item) => activeFilter === '*' || item.filterClass === activeFilter
  );

  return (
    <section className="pt-10 px-4 bg-gray-50 bg-gradient-to-t from-white to-blue-100 sm:px-10" aria-labelledby="portfolio-heading">
      <div className="container mx-auto">
        <header className="text-center mb-12 px-4 sm:px-28">
          <h2 id="portfolio-heading" className="text-2xl sm:text-3xl font-bold mb-4 text-gray-800">Our Portfolio</h2>
          <p className="text-sm sm:text-lg text-gray-600">
            Explore our diverse range of completed projects, from web and app development to immersive game designs. See how we bring ideas to life!
          </p>
        </header>

        <div className="mb-8">
          <ul className="flex flex-wrap justify-center space-x-2 sm:space-x-4 mb-8">
            {filters.map((filter, index) => (
              <li
                key={index}
                className={`cursor-pointer py-2 px-4 ${
                  activeFilter === filter.filter
                    ? 'text-blue-500 font-bold underline'
                    : 'text-gray-600'
                }`}
                onClick={() => handleFilterClick(filter.filter)}
                aria-label={`Filter by ${filter.name}`}
              >
                {filter.name}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-wrap -mx-2 sm:-mx-4 justify-center">
          {filteredItems.map((item, index) => (
            <article
              key={index}
              className="w-full sm:w-1/2 lg:w-1/3 px-2 sm:px-4 mb-8 portfolio-item"
              aria-labelledby={`portfolio-item-${index}`}
            >
              <div className="relative bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg">
                <figure className="relative">
                  <img
                    src={item.imgSrc}
                    alt={`Screenshot of ${item.title}`}
                    className="w-full h-60 object-cover"
                  />
                  <Link
                    to={item.title}
                    data-lightbox="portfolio"
                    data-title={item.title}
                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 hover:opacity-100 transition-opacity"
                    title={`Preview of ${item.title}`}
                    aria-label={`Preview ${item.title}`}
                  >
                    <FaEye className="text-2xl text-blue-500" />
                  </Link>
                  <a
                    href={item.website}
                    className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-lg"
                    target='_blank'
                    rel="noopener noreferrer"
                    title={`Visit ${item.title} website`}
                    aria-label={`Visit ${item.title} website`}
                  >
                    <FaExternalLinkAlt className="text-gray-600" />
                  </a>
                </figure>
                <div className="p-4 text-center">
                  <h3 id={`portfolio-item-${index}`} className="text-xl font-semibold mb-2">{item.title}</h3>
                  <p className="text-gray-600">{item.category}</p>
                  <p className="text-gray-500 text-sm mt-2">{item.description}</p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
